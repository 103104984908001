import React , {useState,useCallback} from "react";
import "../../hojasEstilos/Tickets/sideMenu.css"
import Button from '@mui/material/Button';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TaskIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import TeamIcon from '@mui/icons-material/Diversity3Outlined';
import SolicitudIcon from '@mui/icons-material/EmailOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddEquiposIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VerEquiposIcon from '@mui/icons-material/PeopleAltOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router";

function SideMenu() {

  const navegacion = useNavigate();
  const [expanded, setExpanded] = useState(false);
  

  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded? panel:false);
    },
    []
  );

  const handleCreateEquipoButtonClick = () => { 
    setExpanded(false);
    navegacion("/Tickets/CrearEquipo") 
  };

  const handleVerEquipoButtonClick = () => { 
    setExpanded(false);
    navegacion("/Tickets/VerEquipos");
  }

  const CrearBtnClick = () => {
    navegacion("/Tickets/CreateTicket");
  }

  const handleSolicitudesButtonClick = () => {
    navegacion("/Tickets/Solicitudes");
  }

  const handleMisTareasButtonClick = () => {
    navegacion("/Tickets/AdminTicket");
  }

  return (
    <div className="sideMenu-ContenedorPrincipal">
      <div className="sideMenu-ContenedorPrincipal-CreateShortcut">
        <div className="div-CreateShortcut">
          <Button sx={{ height: "107px", width: "246px", backgroundColor: "#FFFFFF", color: "black", fontSize: "16px" }}
            variant="contained" startIcon={<CreateOutlinedIcon />} onClick={CrearBtnClick}>Crear</Button>
        </div>
      </div>
      <div className="sideMenu-ContenedorPrincipal-ButtonsMenu">
        <Button sx={{ color: "black", height: "63px", marginLeft: "-16px" }} startIcon={<TaskIcon />}
         onClick={handleMisTareasButtonClick}>Mis Tareas</Button>            
        <Accordion sx={{display:"flex", flexWrap:"wrap" , justifyContent: "center" , backgroundColor:"#F9F9F9", boxShadow: "none"}} 
          expanded={expanded ==='panel2'}
          onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{display:"flex" , gap:"5px", height:"63px"}}            
          >
            <TeamIcon sx={{marginRight:"8px"}} />
            <Typography>EQUIPOS </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{display:"flex" , flexWrap: "nowrap" , flexDirection: "column"}} >
            <Button startIcon={<AddEquiposIcon />} sx={{color:"black", marginLeft: "-2px", height:"63px"}}
              onClick={handleCreateEquipoButtonClick}> Crear Equipo</Button>
            <Button startIcon={<VerEquiposIcon />} sx={{color:"black", marginLeft: "-19px", height:"63px"}}
             onClick={handleVerEquipoButtonClick}> Ver Equipo</Button>
          </AccordionDetails>
        </Accordion>
        <Button sx={{ color: "black", height: "63px" , marginLeft:"-12px" }} startIcon={<SolicitudIcon />}
         onClick={handleSolicitudesButtonClick}>Solicitudes</Button>
      </div>
    </div>
  );
}

export default SideMenu;