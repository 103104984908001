import React from "react";
import '../../hojasEstilos/Tickets/TicketHome.css'
import homePicture from '../../recursos/LOGO HALCON webph.webp';


function TicketHome() {
    return (

        <div className="TicketHome-Contenedor-Content">
            <div className="TicketHome-Contenedor-Content-Image">
                <img alt="logoHalcon" src={homePicture}></img>
            </div>
        </div>
    );
}

export default TicketHome;