import React from "react";
import "./../../hojasEstilos/Tickets/Paginas/CrearTicket.css"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function CrearTicket() {
    return (
        <div className="CrearTicket-ContenedorPrincipal">
            <div className="CrearTicket-ContenedorSombra">
                <div className="Contenedor-Formulario">
                    <div className="CrearTicket-Titulo">
                        <EditOutlinedIcon sx={{ height: "38px", width: "38px" }}></EditOutlinedIcon>
                        <h1 style={{fontSize: "28px", fontStyle:"inherit"}}>Crear Solicitud</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrearTicket;