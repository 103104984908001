import React, { useState, useRef, useEffect } from "react";
import "../hojasEstilos/tombola.css";
import sonido from "../utiles/audio/sondoTombola.mp3";
import Loading from "../componentes/Loading";
import { alertaMensaje } from "../utiles/alertas";
import {
  obtenerTipoRifa,
  insertarParticipantesTombola,
  obtenerGanadorTombola,
  cantidadPaticipantes,
  guardarGanadorTombola,
} from "../sevicios/endpoint";
import { useTheme } from "@mui/system";

import TablaGanadorTombola from "../componentes/TablaGanadorTombola.jsx";
import Ruleta from "../componentes/Ruleta.jsx";
const Tombola = () => {

  const [espera, setEspera] = useState(false);
  const [habSelect, setHabSelect] = useState(false);
  const [mostrarGanador, setMostrarGanador] = useState(false);
  const [selectDefault, setSelectDefault] = useState("");
  const [descRifa, setDescRifa] = useState("");
  const [seleccion, setSeleccion] = React.useState(null);
  const [ganadorHalcon, setGanadorHalcon] = useState("");
  const [descripcionRifa, setDescripcionRifa] = useState([]);
  const [intParticipantes, setIntParticipantes] = useState(false);
  const [loading, setLoading] = useState(false);
  const soundRef = useRef(new Audio(sonido));
  const [linkImage,setLinkImage] = useState("");

  const tema = useTheme();
  const tomContenedor = tema.estilosPersonalizados?.tomContenedor;
  const tomTombola = tema.estilosPersonalizados?.tomTombola;
  const tomBoton = tema.estilosPersonalizados?.tomBoton;
  const selectColor = tema.estilosPersonalizados?.selectColor;

  const [rolling, setRolling] = useState(false);
  const [rolling2, setRolling2] = useState(false);
  const [, setFruit1] = useState("0");
  const [, setFruit2] = useState("0");
  const [, setFruit3] = useState("0");
  const [, setFruit4] = useState("0");
  const [, setFruit5] = useState("0");
  const [, setFruit6] = useState("0");
  const [, setFruit7] = useState("0");
  const [, setFruit8] = useState("0");
  const [, setFruit9] = useState("0");
  const [final1, setFinal1] = useState("0");
  const [final2, setFinal2] = useState("0");
  const [final3, setFinal3] = useState("0");
  const [final4, setFinal4] = useState("0");
  const [final5, setFinal5] = useState("0");
  const [final6, setFinal6] = useState("0");
  const [final7, setFinal7] = useState("0");
  const [final8, setFinal8] = useState("0");
  const [final9, setFinal9] = useState("0");

  const slotRef = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const fruits = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  //SIMULACION DEL GIRO DE LA TOMBOLA
  const roll = async () => {
    let data = {
      idFiltro: seleccion || selectDefault.idFiltro,
    };
    let part = await cantidadPaticipantes(data);

    if (part != null) {
      if (descRifa !== "" || selectDefault.descripcion !== "") {   
        if (part.cantidad > 0) {
          playSound(); //Iniciar sonido
          setRolling(true);
          setRolling2(true);

          // Establecer una función para detener la rotación después de 6.5 segundos
          setTimeout(() => {
            setRolling2(false);
            setMostrarGanador(true);
            setEspera(true);
          }, 6500);

          setTimeout(() => {
            setRolling(false);
            setIntParticipantes(false);
            setHabSelect(false);
            stopSound(); //Terminar sonido
          }, 10500); //10.5 segundos

          // Establecer un intervalo para rotar los números cada 0.7 segundos (manteniendo la velocidad)
          const interval = setInterval(() => {
            slotRef.forEach((slot, i) => {
              const selected = triggerSlotRotation(slot.current);              
              switch (i) {
                case 0:
                  setFruit1(selected);
                  break;
                case 1:
                  setFruit2(selected);
                  break;
                case 2:
                  setFruit3(selected);
                  break;
                case 3:
                  setFruit4(selected);
                  break;
                case 4:
                  setFruit5(selected);
                  break;
                case 5:
                  setFruit6(selected);
                  break;
                case 6:
                  setFruit7(selected);
                  break;
                case 7:
                  setFruit8(selected);
                  break;
                case 8:
                  setFruit9(selected);
                  break;
                default:
                  break;
              }
            });
          }, 150);
          // Detener la rotación después de 7 segundos
          setTimeout(() => {
            clearInterval(interval);
            alertaMensaje(
              "¡Felicidades!",
              "El ganador de la rifa es " +
              ganadorHalcon.nombre +
              ", con el número de factura " +
              ganadorHalcon.factura,
              "success"
            );
            guardarGanador();
          }, 6500); // 6.5 segundos
        } else {
          alertaMensaje(
            "No hay facturas para realizar este sorteo",
            "",
            "warning"
          );
        }
      } else {
        alertaMensaje("No hay ninguna rifa disponible", "", "warning");
      }
    }

  };

  //ROTACION DE LOS NUMEROS
  const triggerSlotRotation = (ref) => {
    function setTop(top) {
      ref.style.top = `${top}px`;
    }
    const options = ref.children;
    const randomOption = Math.floor(Math.random() * fruits.length);
    const chosenOption = options[randomOption];
    setTop(-chosenOption.offsetTop + 2);
    return fruits[randomOption];
  };

  //Funcion para iniciar sonido de la tombola
  const playSound = () => {
    soundRef.current.currentTime = 0;
    soundRef.current.play();
  };
  //Funcion para pausar sonido de la tombola
  const stopSound = () => {
    soundRef.current.pause();
  };

  const handleChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    setSeleccion(event.target.value);
    setDescRifa(event.target.options[selectedIndex].text);
    setLinkImage(descripcionRifa[selectedIndex].image);
  };

  useEffect(() => {
    obtenerRifa();
  }, []);



  //Obtener tipo de rifa
  const obtenerRifa = async () => {
    try {
      setLoading(true);
      const resultado = await obtenerTipoRifa();      
      setSelectDefault(resultado.length > 0 ? resultado[0] : "");
      setLinkImage(resultado[0].image);
      setDescripcionRifa(resultado);
    } catch (error) {
      console.error("Error al obtener el tipo de rifa", error);
    } finally {
      setLoading(false);
    }
  };

  //Cargar facturas de los participantes de la tombola
  const insertarParticipantes = async () => {
    try {
      setLoading(true);
      setFinal1("0");
      setFinal2("0");
      setFinal3("0");
      setFinal4("0");
      setFinal5("0");
      setFinal6("0");
      setFinal7("0");
      setFinal8("0");
      setFinal9("0");

      setEspera(false);
      setMostrarGanador(false);
      setHabSelect(true);
      ganadorRifa();
      let data = {
        idFiltro: seleccion || selectDefault.idFiltro,
      };
      const resultado = await insertarParticipantesTombola(data);
      alertaMensaje(resultado.message, "", "success");
      setIntParticipantes(true);
    } catch (error) {
      console.error("Error al cargar los participantes a la tombola", error);
    } finally {
      setLoading(false);
    }
  };

  //Obtener el ganador de la rifa
  const ganadorRifa = async () => {
    try {
      setGanadorHalcon(await obtenerGanadorTombola());
    } catch (error) {
      console.log("Error al obtener el ganador de la rifa: " + error);
    }
  };

  //Guardar el ganador de la rifa
  const guardarGanador = async () => {
    try {
      let data = {
        nombre: ganadorHalcon.nombre,
        cedula: ganadorHalcon.cedula,
        telefono: ganadorHalcon.telefono,
        factura: ganadorHalcon.factura,
        sucursal: ganadorHalcon.sucursal,
        correo: ganadorHalcon.correo,
        rifa: descRifa || selectDefault.descripcion,
      };

      const Winner = ganadorHalcon.factura.padStart(9,'0');
      const facturaDigits =(Winner || "").toString().split("");
      
      setFinal1(facturaDigits[0] || "0");
      setFinal2(facturaDigits[1] || "0");
      setFinal3(facturaDigits[2] || "0");
      setFinal4(facturaDigits[3] || "0");
      setFinal5(facturaDigits[4] || "0");
      setFinal6(facturaDigits[5] || "0");
      setFinal7(facturaDigits[6] || "0");
      setFinal8(facturaDigits[7] || "0");
      setFinal9(facturaDigits[8] || "0");


/*
      const facturaDigits = (ganadorHalcon.factura || "").toString().split("");
      if (ganadorHalcon.factura.length === 4) {
        setFinal1(facturaDigits[4] || "0");
        setFinal2(facturaDigits[5] || "0");
        setFinal3(facturaDigits[6] || "0");
        setFinal4(facturaDigits[7] || "0");
        setFinal5(facturaDigits[8] || "0");
        setFinal6(facturaDigits[0] || "0");
        setFinal7(facturaDigits[1] || "0");
        setFinal8(facturaDigits[2] || "0");
        setFinal9(facturaDigits[3] || "0");
       } else if (ganadorHalcon.factura.length === 3) {
        setFinal1(facturaDigits[3] || "0");
        setFinal1(facturaDigits[4] || "0");
        setFinal2(facturaDigits[5] || "0");
        setFinal3(facturaDigits[6] || "0");
        setFinal5(facturaDigits[7] || "0");
        setFinal6(facturaDigits[8] || "0");
        setFinal7(facturaDigits[0] || "0");
        setFinal8(facturaDigits[1] || "0");
        setFinal9(facturaDigits[2] || "0");
      } else if (ganadorHalcon.factura.length === 5) {
        setFinal1(facturaDigits[5] || "0");
        setFinal1(facturaDigits[6] || "0");
        setFinal2(facturaDigits[7] || "0");
        setFinal3(facturaDigits[8] || "0");
        setFinal5(facturaDigits[0] || "0");
        setFinal6(facturaDigits[1] || "0");
        setFinal7(facturaDigits[2] || "0");
        setFinal8(facturaDigits[3] || "0");
        setFinal9(facturaDigits[4] || "0");
      } else if (ganadorHalcon.factura.length === 6) {
        setFinal1(facturaDigits[6] || "0");
        setFinal2(facturaDigits[7] || "0");
        setFinal3(facturaDigits[8] || "0");
        setFinal4(facturaDigits[0] || "0");
        setFinal5(facturaDigits[1] || "0");
        setFinal6(facturaDigits[2] || "0");
        setFinal7(facturaDigits[3] || "0");
        setFinal8(facturaDigits[4] || "0");
        setFinal9(facturaDigits[5] || "0");
      } else if (ganadorHalcon.factura.length === 7) {
        setFinal1(facturaDigits[7] || "0");
        setFinal2(facturaDigits[8] || "0");
        setFinal3(facturaDigits[0] || "0");
        setFinal4(facturaDigits[1] || "0");
        setFinal5(facturaDigits[2] || "0");
        setFinal6(facturaDigits[3] || "0");
        setFinal7(facturaDigits[4] || "0");
        setFinal8(facturaDigits[5] || "0");
        setFinal9(facturaDigits[6] || "0");
      } else if (ganadorHalcon.factura.length === 8) {
        setFinal1(facturaDigits[8] || "0");
        setFinal2(facturaDigits[0] || "0");
        setFinal3(facturaDigits[1] || "0");
        setFinal4(facturaDigits[2] || "0");
        setFinal5(facturaDigits[3] || "0");
        setFinal6(facturaDigits[4] || "0");
        setFinal7(facturaDigits[5] || "0");
        setFinal8(facturaDigits[6] || "0");
        setFinal9(facturaDigits[7] || "0");
      } else if (ganadorHalcon.factura.length === 9) {
        setFinal1(facturaDigits[0] || "0");
        setFinal2(facturaDigits[1] || "0");
        setFinal3(facturaDigits[2] || "0");
        setFinal4(facturaDigits[3] || "0");
        setFinal5(facturaDigits[4] || "0");
        setFinal6(facturaDigits[5] || "0");
        setFinal7(facturaDigits[6] || "0");
        setFinal8(facturaDigits[7] || "0");
        setFinal9(facturaDigits[8] || "0");
      } else {
        setFinal1(facturaDigits[0] || "0");
        setFinal2(facturaDigits[1] || "0");
        setFinal3(facturaDigits[2] || "0");
        setFinal4(facturaDigits[3] || "0");
        setFinal5(facturaDigits[4] || "0");
        setFinal6(facturaDigits[5] || "0");
        setFinal7(facturaDigits[6] || "0");
        setFinal8(facturaDigits[7] || "0");
        setFinal9(facturaDigits[8] || "0");
      }

*/

      // Insertar al ganador
      await guardarGanadorTombola(data);
    } catch (error) {
      console.log("Error al guardar el ganador: " + error);
    }
  };
  return (
    <>
      {loading ? <Loading /> : null}
      <div className="tom">
        <div style={tomContenedor} className="tomContenedor">
          <div className="tomAleatorio ruleta" style={tomTombola}>
            <Ruleta
              slotRef={slotRef}
              fruits={fruits}
              rolling2={rolling2}
              rolling={rolling}
              final1={final1}
              final2={final2}
              final3={final3}
              final4={final4}
              final5={final5}
              final6={final6}
              final7={final7}
              final8={final8}
              final9={final9}
              habSelect={habSelect}
              seleccion={seleccion}
              handleChange={handleChange}
              selectColor={selectColor}
              descripcionRifa={descripcionRifa}
              tomBoton={tomBoton}
              intParticipantes={intParticipantes}
              insertarParticipantes={insertarParticipantes}
              roll={roll}
              espera={espera}
            />
          </div>
          {/* <div
              style={{
                ...tomBoton,
                opacity: rolling ? 0.5 : 1, // Reducimos la opacidad si rolling es true para hacerlo parecer desactivado
                pointerEvents: rolling ? "none" : "auto", // Evitamos que se reciban eventos de clics si rolling es true
                cursor: rolling ? "not-allowed" : "pointer", // Cambiamos el cursor si rolling es true
              }}
              className={rolling ? "roll rolling" : "roll"}
              onClick={
                !intParticipantes || rolling
                  ? (event) => event.stopPropagation()
                  : insertarParticipantes
              }
            >
              {!intParticipantes
                ? "Cargar Facturas"
                : !rolling
                ? "Girar"
                : rolling && espera
                ? "¡Felicidades!"
                : "Buscando ganador"}
            </div>
          </div> */}
          <div className="tomAleatorio rifa">
            <div className="logoHalcon">
              <img alt="Selected" src={linkImage} />
            </div>
          </div>
          <div className="tomAleatorio tabla">
            <TablaGanadorTombola
              mostrarGanador={mostrarGanador}
              ganadorHalcon={ganadorHalcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Tombola;
